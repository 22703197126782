import { graphql } from "@/federatedGql/gql"

export const GET_SELLER_INFO_BY_SLUG = graphql(`
  query getSellerInfoBySlug($filter: CompanyPublicFilter!) {
    companyPublic(filter: $filter) {
      ... on CompanyPublicResult {
        __typename
        companies {
          name
          id
          institusiId
          isUmkk
          companyAddresses {
            villageAreaCode
            regionDetail {
              provinceName
            }
          }
          shippingAddresses {
            regionDetail {
              cityName
              provinceName
            }
          }
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_SELLER_PROFILE_INFO = graphql(`
  query getSellerProfileInfo($id: String!) {
    getInstitutionByID(id: $id) {
      ... on Institution {
        company {
          id
          status
          isUmkk
          name
          bentukUsaha
          website
          companyAddresses {
            villageAreaCode
            fullAddress
            postalCode
          }
        }
      }
      ... on GenericError {
        reqId
        message
        code
      }
    }
  }
`)

export const GET_IZIN_USAHA_INFO = graphql(`
  query getIzinUsahaInfo($id: String!) {
    getInstitutionByID(id: $id) {
      ... on Institution {
        company {
          izin {
            izinUsahaExpired
            id
            izinUsahaInstitusi
            isVerify
            jenisIzinName
            izinUsahaId
            izinUsahaNo
          }
        }
      }
      ... on GenericError {
        __typename
        reqId
        message
        code
      }
    }
  }
`)

export const GET_DAFTAR_KBLI_CODE = graphql(`
  query getDaftarKBLIInfo($filter: CompanyPublicFilter!) {
    companyPublic(filter: $filter) {
      ... on CompanyPublicResult {
        __typename
        companies {
          oss {
            project {
              kbli {
                id
                name
                kbli
              }
            }
          }
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_DAFTAR_KBLI_INFO = graphql(`
  query getDaftarKBLI($page: Int, $perPage: Int, $codes: [String]) {
    getAllKBLI(codes: $codes, page: $page, perPage: $perPage) {
      total
      perPage
      items {
        title
        description
        code
      }
    }
  }
`)

export const GET_STORE_NOTES = graphql(`
  query getStoreNotes($filter: GetStoreNotesFilter) {
    storeNotes(filter: $filter) {
      ... on StoreNotesList {
        __typename
        notes {
          content
          createdAt
          id
          noteType
          sellerId
          title
          updatedAt
        }
      }
      ... on Error {
        reqId
        message
        code
      }
    }
  }
`)

export const GET_SELLER_SCORE = graphql(`
  query getSellerScore($input: SellerScoreInput!) {
    sellerScore(input: $input) {
      ... on SellerScoreResponse {
        details {
          componentName
          notes
          score
          value
          valueUnit
          weightPercentage
        }
        finalScore
        orderSignedCount
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)
