"use client"

import { Spinner } from "shared-ui"
import useGetSellerScore from "../hooks/useGetSellerScore"

type TSellerGeneralScoreInfoSectionProps = {
  sellerId: string
}

const SellerGeneralScoreInfoSection = ({
  sellerId,
}: TSellerGeneralScoreInfoSectionProps) => {
  const { data: sellerScore, isFetching: isFetchingSellerScore } =
    useGetSellerScore({
      variables: {
        input: {
          sellerId,
        },
      },
    })

  return (
    <div className="mr-12 flex gap-6">
      <div className="ga-4 flex flex-col">
        <div className="text-caption-lg-regular text-tertiary300">
          Total Nilai Penyedia
        </div>
        {isFetchingSellerScore ? (
          <Spinner size="small" />
        ) : (
          <div>
            <span className="text-body-lg-bold text-tertiary500">
              {sellerScore?.finalScore ?? "-"}
            </span>
            <span className="text-caption-lg-regular text-tertiary300">
              /100
            </span>
          </div>
        )}
      </div>
      <div className="ga-4 flex flex-col">
        <div className="text-caption-lg-regular text-tertiary300">
          Total Pesanan
        </div>
        {isFetchingSellerScore ? (
          <Spinner size="small" />
        ) : (
          <div className="text-body-lg-bold text-tertiary500">
            {sellerScore?.orderSignedCount || "-"}
          </div>
        )}
      </div>
    </div>
  )
}

export default SellerGeneralScoreInfoSection
