import React, { useState } from "react"
import { ChevronDown } from "react-feather"
import { Modal, Button } from "shared-ui"

type TStoreInfoModalProps = {
  isActive: boolean
  toggleStoreModal: () => void
  sellerStoreInfo: {
    storeName: string
    description: string
    city: string
  }
}

type TRenderStoreFieldSetProps = {
  key: number
  label: string
  value: string
  isShowMoreDesc: boolean
  toggleShowDesc: () => void
}

const renderStoreFieldSet = ({
  key,
  label,
  value,
  isShowMoreDesc,
  toggleShowDesc,
}: TRenderStoreFieldSetProps) => {
  const showMoreBtn =
    label === "Deskripsi Toko" && value.length >= 250 && !isShowMoreDesc

  return (
    <div key={`store_info_${key}`} className="mb-4 flex text-sm">
      <div className="w-1/3 text-sm text-tertiary300">{label}</div>
      <div>:</div>
      <div className="w-2/3 pl-1 font-semibold text-tertiary500">
        {showMoreBtn ? (
          <>
            {value.substring(0, 250)}
            <a
              onClick={toggleShowDesc}
              href="#"
              className="mt-3 flex justify-center text-center text-secondary500"
            >
              Lihat Selengkapnya
              <ChevronDown size={18} className="relative top-[2px] ml-2" />
            </a>
          </>
        ) : (
          value
        )}
      </div>
    </div>
  )
}

const StoreInfoModal = ({
  isActive,
  toggleStoreModal,
  sellerStoreInfo,
}: TStoreInfoModalProps) => {
  const [isShowMoreDesc, setIsShowMoreDesc] = useState<boolean>(false)
  const toggleShowDesc = () => setIsShowMoreDesc((prev) => !prev)

  const storeInfoList = [
    {
      label: "Nama Toko",
      value: sellerStoreInfo.storeName,
    },
    {
      label: "Deskripsi Toko",
      value: sellerStoreInfo.description,
    },
    {
      label: "Kota",
      value: sellerStoreInfo.city,
    },
  ]

  const handleBack = () => {
    if (isShowMoreDesc) {
      toggleShowDesc()
    }
    toggleStoreModal()
  }

  return (
    <Modal
      open={isActive}
      title="Info Toko"
      onOpenChange={toggleStoreModal}
      classNames={{
        content: "md:w-[500px] lg:w-[800px] max-h-[65vh] !overflow-hidden",
      }}
    >
      <div className="mt-4 flex flex-col gap-4">
        <div className="max-h-[45vh] overflow-y-auto">
          {storeInfoList.map((item, idx) => {
            return renderStoreFieldSet({
              key: idx,
              label: item.label,
              value: item.value,
              isShowMoreDesc,
              toggleShowDesc,
            })
          })}
        </div>

        <Button
          id="store-info-back-btn"
          variant="outline2"
          className="mt-4 w-full"
          onClick={handleBack}
        >
          Kembali
        </Button>
      </div>
    </Modal>
  )
}

export default StoreInfoModal
