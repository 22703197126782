import { type ColumnDef } from "@tanstack/react-table"

import {
  type CompanyIzinUsahaType,
  SearchProductSortField,
  SearchProductSortOrder,
  OrderRatingDetailType,
  OrderRatingDetailSubType,
} from "@/federatedGql/graphql"
import { formatDateToYYYY_MM_DD } from "@/utils/timestamp"

export const tableIzinUsahaColumn: ColumnDef<CompanyIzinUsahaType, unknown>[] =
  [
    {
      header: "No.",
      id: "no",
      accessorKey: "no",
      size: 5,
      cell: ({ row }) => row.index + 1,
    },
    {
      header: "Izin Usaha",
      id: "name",
      accessorKey: "name",
      size: 10,
      cell: ({ row }) => row.original.jenisIzinName || "-",
    },
    {
      header: "No. Surat",
      id: "number",
      accessorKey: "number",
      size: 10,
      cell: ({ row }) => row.original.izinUsahaNo || "-",
    },
    {
      header: "Berlaku Sampai",
      id: "activeUntil",
      accessorKey: "activeUntil",
      size: 10,
      cell: ({ row }) =>
        formatDateToYYYY_MM_DD(row.original.izinUsahaExpired || "-"),
    },
    {
      header: "Instansi Pemberi",
      id: "instansi",
      accessorKey: "sourceData",
      size: 10,
      cell: ({ row }) => row.original.izinUsahaInstitusi || "-",
    },
    {
      header: "Status",
      id: "isVerified",
      accessorKey: "isVerified",
      size: 10,
      cell: ({ row }) => (row.original.isVerify ? "verified" : "unverified"),
    },
  ]

export const sellerCatalogueSortOptions = [
  {
    value: "ctime",
    label: "Terbaru",
    sortBy: {
      field: SearchProductSortField.CreatedAt,
      order: SearchProductSortOrder.Desc,
    },
  },
  {
    value: "unit-sold",
    label: "Terlaris",
    sortBy: {
      field: SearchProductSortField.UnitSold,
      order: SearchProductSortOrder.Desc,
    },
  },
  {
    value: "price-asc",
    label: "Harga Terendah",
    sortBy: {
      field: SearchProductSortField.Price,
      order: SearchProductSortOrder.Asc,
    },
  },
  {
    value: "price-desc",
    label: "Harga Tertinggi",
    sortBy: {
      field: SearchProductSortField.Price,
      order: SearchProductSortOrder.Desc,
    },
  },
  {
    value: "tkdn-bmp",
    label: "TKDN + BMP Tertinggi",
    sortBy: {
      field: SearchProductSortField.TkdnBmp,
      order: SearchProductSortOrder.Desc,
    },
  },
]

export const sellerRatingTypeOrder = [
  OrderRatingDetailType.Product,
  OrderRatingDetailType.Seller,
  OrderRatingDetailType.Shipment,
]

export const getRatingSectionTitle = (
  activeRatingTab: OrderRatingDetailType,
  activeRatingSubTab?: OrderRatingDetailSubType
) => {
  switch (activeRatingTab) {
    case OrderRatingDetailType.Seller:
      return "Penyedia"
    case OrderRatingDetailType.Shipment:
      return activeRatingSubTab === OrderRatingDetailSubType.Custom
        ? "Pengiriman Kurir Penyedia"
        : "Penyelesaian Pekerjaan"
    default:
      return "Produk"
  }
}

export const getRatingTypeLabel = (type: OrderRatingDetailType) => {
  switch (type) {
    case OrderRatingDetailType.Product:
      return "Produk"
    case OrderRatingDetailType.Seller:
      return "Pelayanan Penyedia"
    default:
      return "Akurasi Waktu"
  }
}

export const getRatingTypeTooltipTitle = (type: OrderRatingDetailType) => {
  switch (type) {
    case OrderRatingDetailType.Product:
      return "Nilai rata-rata dari Penilaian Produk"
    case OrderRatingDetailType.Seller:
      return "Nilai rata - rata dari Pelayanann Penyedia"
    default:
      return "Nilai rata - rata dari Pengiriman Kurir dan Penyelesaian Pekerjaan"
  }
}

export const getSellerScoreDescription = (component?: string) => {
  switch (component) {
    case "Persentase Respond Negosiasi":
      return {
        notes:
          "Persentasi penyedia merespond negosiasi dibandingkan dengan total negosiasi yang telah selesai.",
        goodPoint: "Penyedia merespon negosiasi Negosiasi direspond penyedia",
        badPoint: "Penyedia tidak merespond negosiasi sehingga menjadi expired",
      }
    case "Penyedia Menerima Pesanan":
      return {
        notes:
          "Persentasi penerimaan pesanan dibandingkan dengan total pesanan yang diajukan pembeli",
        goodPoint: "Penyedia menerima pesanan dan menandatangani surat pesanan",
        badPoint:
          "Penyedia menolak pesanan, Penyedia tidak merespond pesanan sehingga menjadi expired",
      }
    case "Penyelesaian Status Pesanan":
      return {
        notes:
          "Persentasi pesanan yang telah selesai dan Berita Acara Serah Terima sudah ditandatangani dibandingkan dengan Total Pesanan yang telah selesai atau dibatalkan",
        goodPoint:
          "Pesanan telah selesai dan Berita Acara Serah Terima sudah ditandatangani pembeli dan penyedia",
        badPoint: "Pesanan dibatalkan karena kesalahan penyedia",
      }
    case "Penilaian PPK: Produk":
      return {
        notes: "Nilai rata-rata penilaian dari PPK untuk kualitas Produk",
        goodPoint: "",
        badPoint: "",
      }
    case "Penilaian PPK: Pelayanan Penyedia":
      return {
        notes: "Nilai rata-rata penilaian dari PPK untuk Pelayanan Penyedia",
        goodPoint: "",
        badPoint: "",
      }
    case "Penilaian PPK: Akurasi Waktu":
      return {
        notes:
          "Nilai rata-rata penilaian dari PPK untuk Pengiriman Penyedia dan Penyelesaian Pekerjaan",
        goodPoint: "",
        badPoint: "",
      }
    default:
      return {}
  }
}

export const defaultSellerScoreDetail = [
  {
    componentName: "Persentase respond negosiasi",
    notes: "-",
    score: null,
    value: null,
    valueUnit: null,
    weightPercentage: null,
  },
  {
    componentName: "Penyedia menerima pesanan",
    notes: "-",
    score: null,
    value: null,
    valueUnit: null,
    weightPercentage: null,
  },
  {
    componentName: "Penyelesaian status pesanan",
    notes: "-",
    score: null,
    value: null,
    valueUnit: null,
    weightPercentage: null,
  },
  {
    componentName: "Penilaian PPK: Produk",
    notes: "-",
    score: null,
    value: null,
    valueUnit: null,
    weightPercentage: null,
  },
  {
    componentName: "Penilaian PPK: Pelayanan Penyedia",
    notes: "-",
    score: null,
    value: null,
    valueUnit: null,
    weightPercentage: null,
  },
  {
    componentName: "Penilaian PPK: Akurasi Waktu",
    notes: "-",
    score: null,
    value: null,
    valueUnit: null,
    weightPercentage: null,
  },
]

export const ratingSubTabMenu = [
  {
    id: "seller-shipment-rating-btn",
    name: OrderRatingDetailSubType.Custom,
    tooltipTitle: "Nilai rata - rata dari Pengiriman Kurir",
    title: "Pengiriman Kurir Penyedia",
  },
  {
    id: "seller-job-completion-rating-btn",
    name: OrderRatingDetailSubType.NoShipment,
    tooltipTitle: "Nilai rata - rata dari Penyelesaian Pekerjaan",
    title: "Penyelesaian Pekerjaan",
  },
]
