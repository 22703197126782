"use client"

import { useChatStore } from "chat"
import { useDialogAuth } from "gtp-ui"
import Link from "next/link"
import { useRouter } from "next/navigation"
import React, { useState } from "react"
import { Alert, Avatar, Button, useSnackbar } from "shared-ui"
import {
  IS_CANARY_PREPROD,
  IS_PRODUCTION,
  ZENDESK_URL,
  formatProvince,
} from "shared-utils"
import { getUserInfo } from "shared-utils/authentication"

import SellerCatalogue from "./components/SellerCatalogue"
import SellerGeneralScoreInfoSection from "./components/SellerGeneralScoreInfoSection"
import SellerStatus from "./components/SellerStatus"
import StoreInfoModal from "./components/StoreInfoModal"
import TabBar from "./components/TabBar"

import PageNotFound from "./PageNotFound"
import { type TChatCompanyResponse } from "./[slug]/hooks/useChatCompany"
import ROUTE from "@/config/route"
import {
  type CompanyPublicResult,
  type StoreInformations,
} from "@/federatedGql/graphql"
import capitalize from "@/utils/capitalize"

interface ISellerProfileProps {
  username: string
  initialDataSeller?: CompanyPublicResult
  initialDataChatCompany?: TChatCompanyResponse
  initialDataStore: StoreInformations
  isSellerBlacklisted?: boolean
  children: React.ReactNode
  isRatingReviewEnabled?: boolean
  showCatalogue?: boolean
  isProductAccelerationEnabled?: boolean
  isNewTaxCalculationEnabled?: boolean
}

const SellerLayout = ({
  username,
  initialDataSeller,
  initialDataChatCompany,
  initialDataStore,
  isSellerBlacklisted,
  children,
  isRatingReviewEnabled,
  showCatalogue = true,
  isProductAccelerationEnabled = false,
  isNewTaxCalculationEnabled = false,
}: ISellerProfileProps) => {
  const { enqueueSnackbar } = useSnackbar()

  const router = useRouter()
  const { setSeller } = useChatStore()
  const session = getUserInfo()
  const isAuthenticated = Boolean(session?.user?.id)
  const { setDialogAuthData, openDialogAuth } = useDialogAuth()

  const storeInformation =
    initialDataStore?.__typename === "StoreInformations"
      ? initialDataStore.items
      : []

  const shippingAddress =
    initialDataSeller?.companies?.[0]?.shippingAddresses?.[0]

  const sellerAddress = {
    cityName: capitalize(shippingAddress?.regionDetail.cityName || ""),
    provinceName: formatProvince(
      shippingAddress?.regionDetail.provinceName || "-"
    ),
  }

  const [isStoreInfoModalActive, setIsStoreModalActive] =
    useState<boolean>(false)

  const toggleStoreModal = () => setIsStoreModalActive((state) => !state)

  const handleOnClickAskSeller = () => {
    if (!isAuthenticated) {
      setDialogAuthData({
        description:
          "Anda perlu masuk melalui Manajemen Akun Terpusat untuk melanjutkan proses pembelian.",
        title: "Mohon Masuk Terlebih Dahulu",
      })
      openDialogAuth()
      return
    }
    if (
      initialDataChatCompany?.users?.[0]?.id &&
      initialDataChatCompany?.companies?.[0]?.name
    ) {
      const payloadSeller = {
        sellerId:
          !IS_PRODUCTION || IS_CANARY_PREPROD
            ? initialDataSeller?.companies?.[0]?.institusiId || ""
            : initialDataChatCompany.users[0].id,
        sellerName: initialDataChatCompany.companies[0].name,
      }
      setSeller(payloadSeller)
      router.push(ROUTE.chat())
    } else {
      enqueueSnackbar({
        message: "Gagal mendapatkan data penjual",
        type: "error",
      })
    }
  }

  const sellerStoreInfo = {
    storeName: storeInformation?.[0]?.name || "",
    description: storeInformation?.[0]?.description || "-",
    city: `${sellerAddress.cityName}, ${sellerAddress.provinceName}`,
  }

  const storeAvatarImgUrl = storeInformation?.[0]?.imageUrl
    ? { url: storeInformation?.[0]?.imageUrl }
    : { text: initialDataSeller?.companies?.[0]?.name || "" }

  if (!initialDataSeller?.companies?.length) {
    return <PageNotFound />
  }

  return (
    <div className="mx-auto mt-6 flex w-full max-w-[1440px] flex-col md:gap-2 md:px-4 lg:gap-6 lg:px-10 xl:px-[236px]">
      {isSellerBlacklisted && (
        <Alert
          variant="warning"
          title="Toko Sedang Masuk Daftar Hitam"
          description={
            <>
              Toko ini terkena sanksi daftar hitam karena melanggar aturan yang
              berlaku. Apabila ini merupakan kekeliruan, silakan hubungi{" "}
              <Link
                href={ZENDESK_URL}
                target="_blank"
                className="font-semibold !text-secondary500 hover:underline"
              >
                Pusat Bantuan
              </Link>{" "}
              kami.
            </>
          }
        />
      )}
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <Avatar online={false} size="lg" src={storeAvatarImgUrl} />
          <div className="flex flex-col gap-1">
            <h2 className="text-xl font-bold text-tertiary500">
              {initialDataSeller?.companies?.[0]?.name}
            </h2>
            <div className="flex flex-row items-center gap-1">
              <SellerStatus
                status={isSellerBlacklisted ? "inactive" : "online"}
              />
              <p
                aria-hidden="true"
                className="text-center text-3xl text-tertiary200"
              >
                &#183;
              </p>
              <p className="text-sm font-normal text-tertiary500">
                {sellerAddress.cityName}
              </p>
            </div>
          </div>
        </div>
        <div className="flex">
          {isRatingReviewEnabled && (
            <SellerGeneralScoreInfoSection
              sellerId={initialDataSeller?.companies?.[0]?.institusiId || ""}
            />
          )}
          <Button
            id="seller-store-info-btn"
            onClick={toggleStoreModal}
            variant="outline1"
            className="mr-2 w-[88px]"
          >
            Info Toko
          </Button>
          <Button
            id="seller-chat-btn"
            onClick={handleOnClickAskSeller}
            variant="outline1"
            className="w-[110px]"
            disabled={
              isAuthenticated ? !initialDataChatCompany?.users?.[0]?.id : false
            }
          >
            Chat Penjual
          </Button>
        </div>
      </div>
      <TabBar
        sellerSlug={username}
        isRatingReviewEnabled={isRatingReviewEnabled}
      />
      {children}
      <StoreInfoModal
        isActive={isStoreInfoModalActive}
        toggleStoreModal={toggleStoreModal}
        sellerStoreInfo={sellerStoreInfo}
      />
      {showCatalogue && (
        <SellerCatalogue
          sellerId={initialDataSeller?.companies?.[0]?.institusiId || ""}
          sellerSlug={username}
          isRatingReviewEnabled={isRatingReviewEnabled}
          isProductAccelerationEnabled={isProductAccelerationEnabled}
          isNewTaxCalculationEnabled={isNewTaxCalculationEnabled}
        />
      )}
    </div>
  )
}

export default SellerLayout
