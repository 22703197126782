import { useRouter, usePathname } from "next/navigation"
import { TabBar, TabBarItem, Divider } from "shared-ui"

/* eslint-disable */
export enum EnumTab {
  ProfilPenyedia = "PROFIL-PENYEDIA",
  CatatanKebijakan = "CATATAN-KEBIJAKAN",
  DaftarKBLI = "DAFTAR-KBLI",
  PenilaianPPK = "PENILAIAN-PPK",
}

type TTabBar = {
  sellerSlug: string
  isRatingReviewEnabled?: boolean
}

const TabBarComponent = ({ sellerSlug, isRatingReviewEnabled }: TTabBar) => {
  const pathname = usePathname()
  const menuItems = [
    {
      label: "Profil Penyedia",
      value: EnumTab.ProfilPenyedia,
      url: `/${sellerSlug}`,
      isActive:
        (!pathname?.includes("notes") &&
          !pathname?.includes("kbli") &&
          !pathname?.includes("rating-review-ppk")) ??
        false,
    },
    {
      label: "Catatan & Kebijakan",
      value: EnumTab.CatatanKebijakan,
      url: `/${sellerSlug}/notes`,
      isActive: pathname?.includes("notes") ?? false,
    },
    {
      label: "Daftar KBLI",
      value: EnumTab.DaftarKBLI,
      url: `/${sellerSlug}/kbli`,
      isActive: pathname?.includes("kbli") ?? false,
    },
    ...(isRatingReviewEnabled
      ? [
          {
            label: "Penilaian PPK",
            value: EnumTab.PenilaianPPK,
            url: `/${sellerSlug}/rating-review-ppk`,
            isActive: pathname?.includes("rating-review-ppk") ?? false,
          },
        ]
      : []),
  ]

  const router = useRouter()

  return (
    <div>
      <TabBar className="">
        {menuItems.map((item, key) => (
          <TabBarItem
            key={key}
            title={item.label}
            href="#"
            onClick={() => {
              router.push(item.url)
            }}
            isActive={Boolean(item.isActive)}
          />
        ))}
      </TabBar>
      <Divider variant="normal" />
      {/* {getDetail(tabActive)} */}
    </div>
  )
}

export default TabBarComponent
