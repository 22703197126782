import { type ChangeEvent } from "react"
import { Search } from "react-feather"
import { Select, TextField, type TSelectOption } from "shared-ui"

import { sellerCatalogueSortOptions } from "../../../constant"

type TSearchAndSortFieldsProps = {
  keywordSearch?: string | null
  sellerSlug?: string
  handleOnChangeKeyWord: (value: string | undefined) => void
  handleKeywordBlur?: () => void
  handleSortChange: (value: TSelectOption) => void
  sortBy?: string | null
}

const SearchAndSortFields = ({
  keywordSearch,
  handleOnChangeKeyWord,
  handleKeywordBlur,
  handleSortChange,
  sortBy,
}: TSearchAndSortFieldsProps) => {
  const selectedSort =
    sellerCatalogueSortOptions.find((opt) => opt.value === sortBy) ||
    sellerCatalogueSortOptions[0]

  const handleKeywordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const keywordChange = e.target.value === "" ? undefined : e.target.value
    handleOnChangeKeyWord(keywordChange)
  }

  return (
    <div className="mb-8 mt-2 flex justify-between gap-2">
      <div className="flex w-1/3">
        <TextField
          iconLeft={Search}
          placeholder="Cari produk ditoko ini..."
          className="w-27"
          id="seller-catalogue-search-input"
          onChange={handleKeywordChange}
          value={keywordSearch ?? ""}
          onBlur={handleKeywordBlur}
        />
      </div>

      <div className="flex items-center gap-2">
        <span className="text-caption-lg-bold block">Urutkan:</span>
        <div className="w-[192px]">
          <Select
            styles={{
              menu: (baseStyles) => ({
                ...baseStyles,
                zIndex: 2,
                background: "#F5F6F9 !important",
              }),
            }}
            isSearchable={false}
            value={selectedSort}
            options={sellerCatalogueSortOptions}
            onChange={(value) => {
              handleSortChange(value as TSelectOption)
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default SearchAndSortFields
