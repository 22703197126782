export const formatDateTime = (date: Date) => {
  if (!date) {
    return ""
  }
  const hour = date.getHours().toString().padStart(2, "0")
  const minutes = date.getMinutes().toString().padStart(2, "0")

  return `${hour}:${minutes}`
}

export const formatDate = (
  date: Date,
  options?: Intl.DateTimeFormatOptions
) => {
  if (!date) {
    return ""
  }

  return date.toLocaleDateString("id", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    ...options,
  })
}

export const getTimestampChat = (unixTime: number) => {
  const dateChat: Date = new Date(unixTime)
  const dateNow: Date = new Date()

  const diffTime: number = Math.abs(dateNow.getTime() - dateChat.getTime())
  const diffDays: number = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  const minutes = Math.ceil(diffTime / (1000 * 60))
  if (minutes < 60) {
    return `${minutes} mnt`
  }

  if (minutes >= 60 && minutes <= 24 * 60) {
    return formatDateTime(dateChat)
  }

  if (minutes > 24 * 60 && minutes <= 24 * 60 * 7) {
    return `${diffDays} hari lalu`
  }

  if (minutes > 24 * 60 * 7) {
    return formatDate(dateChat)
  }
}

export const getTimeDifference = (date: Date) => {
  const now = new Date()
  const differenceInSeconds = Math.floor(
    (now.getTime() - date.getTime()) / 1000
  )
  const minute = 60
  const hour = minute * 60
  const day = hour * 24
  const week = day * 7

  switch (true) {
    case differenceInSeconds < minute:
      return "Baru saja"
    case differenceInSeconds < hour:
      const minutesAgo = Math.floor(differenceInSeconds / minute)
      return `${minutesAgo} menit yang lalu`
    case differenceInSeconds < day:
      const hoursAgo = Math.floor(differenceInSeconds / hour)
      return `${hoursAgo} jam yang lalu`
    case differenceInSeconds < week:
      const daysAgo = Math.floor(differenceInSeconds / day)
      return `${daysAgo} hari yang lalu`
    default:
      return date.toLocaleDateString()
  }
}

export const formatDateToYYYY_MM_DD = (dateString: string) => {
  const date = new Date(dateString.replace(" +0000 UTC", "Z"))
  const splittedDate = date.toISOString().split("T")
  const hour = date.getHours().toString().padStart(2, "0")
  const minutes = date.getMinutes().toString().padStart(2, "0")
  const seconds = date.getSeconds().toString().padStart(2, "0")

  return `${splittedDate[0]} ${hour}:${minutes}:${seconds}`
}
