import { Button } from "shared-ui"
import Image from "next/image"
import { useRouter } from "next/navigation"
import NotFound404 from "shared-assets/images/404.webp"

const PageNotFound = () => {
  const router = useRouter()

  const handleClickButton = () => {
    router.push("/")
  }

  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex w-[514px] flex-col items-center justify-center gap-6 py-12">
        <Image
          src={NotFound404}
          alt="empty-product-image"
          width={390}
          height={223}
        />
        <div className="text-center">
          <h2 className="mb-4 text-lg font-bold text-tertiary500">
            Maaf, Halaman Tidak Ditemukan.
          </h2>
          <p className="text-sm font-normal text-tertiary300">
            Kami tidak dapat menemukan halaman yang sesuai dengan permintaan
            Anda. Ini mungkin disebabkan oleh salah ketik URL, perubahan pada
            halaman, atau halaman yang telah dihapus.
          </p>
        </div>
        <Button
          id="btn-back-notfound"
          variant="solid"
          onClick={handleClickButton}
        >
          Kembali
        </Button>
      </div>
    </div>
  )
}

export default PageNotFound
