import type { VariantProps } from "class-variance-authority"
import { cva } from "class-variance-authority"

const statusDotVariance = cva(["rounded-full", "w-[10px]", "h-[10px]"], {
  variants: {
    status: {
      online: ["bg-success500"],
      inactive: ["bg-tertiary300"],
    },
  },
})

type TStatusDotVariance = VariantProps<typeof statusDotVariance>

type TStatus = Exclude<TStatusDotVariance["status"], null | undefined>

type TSellerStatusProps = {
  status: TStatus
}

const STATUS_LABEL: Record<TStatus, string> = {
  online: "Online",
  inactive: "Tidak Aktif",
}

const SellerStatus = ({ status }: Readonly<TSellerStatusProps>) => {
  return (
    <div className="flex flex-row items-center gap-1">
      <div className={statusDotVariance({ status })} />
      <p className="text-sm font-normal text-tertiary500">
        {STATUS_LABEL[status]}
      </p>
    </div>
  )
}

export default SellerStatus
