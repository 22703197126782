"use client"
import debounce from "lodash.debounce"
import Link from "next/link"
import { useRouter, useSearchParams } from "next/navigation"
import { useMemo, useState } from "react"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import { CDN_URL } from "shared-config/clientConfig"
import {
  EmptyState,
  Pagination,
  Select,
  type SingleValue,
  type TSelectOption,
} from "shared-ui"

import SearchAndSortFields from "./components/SearchAndSortFields"
import ProductCard from "@/components/product-card"
import Skeleton from "@/components/skeleton"

import { sellerCatalogueSortOptions } from "../../constant"
import ROUTE from "@/config/route"
import {
  SearchProductStrategy,
  type SearchProductSortInput,
} from "@/federatedGql/graphql"
import useSearchProduct from "@/hooks/product/useSearchProduct"

type TSellerCatalogueProps = {
  sellerId: string
  sellerSlug: string
  isRatingReviewEnabled?: boolean
  isProductAccelerationEnabled?: boolean
  isNewTaxCalculationEnabled?: boolean
}

const SellerCatalogue = ({
  sellerId,
  sellerSlug,
  isRatingReviewEnabled,
  isProductAccelerationEnabled = false,
  isNewTaxCalculationEnabled = false,
}: TSellerCatalogueProps) => {
  const searchParams = useSearchParams()
  const router = useRouter()

  const catalogueSearch = searchParams?.get("catalogueSearch")
  const pageParam = searchParams?.get("page") ?? "1"
  const sortBy = searchParams?.get("sortBy")

  const [keyword, setKeyword] = useState<string | null | undefined>(
    catalogueSearch
  )

  const sellerCataloguePerpageOptions = [10, 20, 30, 40, 50].map((item) => {
    return { value: `${item}`, label: `${item} Data` }
  })

  const [selectedPerpage, setSelectedPerpage] = useState<
    TSelectOption | undefined
  >(
    sellerCataloguePerpageOptions.find((opt) => opt.value === sortBy) ||
      sellerCataloguePerpageOptions[0]
  )

  const [isSellerCatalogueSearchEnabled, setIsSellerCatalogueSearchEnabled] =
    useState<boolean>(false)

  const perPage = parseInt(selectedPerpage?.value as string)

  const debounceSearchKeyword = useMemo(
    () => debounce(() => setIsSellerCatalogueSearchEnabled(true), 500),
    []
  )

  const scrollToCatalogueWrapper = () => {
    setTimeout(() => {
      const catalogueWrapper = document.getElementById(
        "seller-catalogue-wrapper"
      )
      const offset = 108
      const bodyRect = document.body.getBoundingClientRect().top
      const elementRect = catalogueWrapper?.getBoundingClientRect().top
      const elementPosition = (elementRect ?? 450) - bodyRect
      const offsetPosition = elementPosition - offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      })
    }, 300)
  }

  const handleOnChangeKeyWord = (val: string | undefined) => {
    const queryParams = new URLSearchParams(searchParams?.toString())
    queryParams.delete("page")
    queryParams.set("catalogueSearch", val ?? "")
    router.replace(`/${sellerSlug}?${queryParams.toString()}`)
    setKeyword(val)
    debounceSearchKeyword()
    scrollToCatalogueWrapper()
  }

  const handleKeywordBlur = () => {
    setIsSellerCatalogueSearchEnabled(false)
  }

  const handleSortChange = (value: SingleValue<TSelectOption>) => {
    if (!value) return

    const queryParams = new URLSearchParams(searchParams?.toString())
    queryParams.set("sortBy", value?.value.toString())
    queryParams.set("page", "1")
    debounceSearchKeyword()
    router.replace(`/${sellerSlug}?${queryParams.toString()}`)
    scrollToCatalogueWrapper()
  }

  const filterFields = {
    strategy: SearchProductStrategy.SellerCatalogue,
    keyword: keyword,
    sellerId: sellerId,
  }

  const { data: listProductSearch, isLoading } = useSearchProduct({
    filter: {
      input: {
        sort: [
          sellerCatalogueSortOptions.find((val) => val.value === sortBy)
            ?.sortBy ??
            (sellerCatalogueSortOptions[0]?.sortBy as SearchProductSortInput),
        ],
        filter: filterFields,
        pagination: {
          page: parseInt(pageParam),
          perPage: perPage,
        },
      },
    },
    options: {
      enabled:
        isSellerCatalogueSearchEnabled || keyword === null || keyword !== "",
    },
  })

  const handlePageChange = (pageNumber: number) => {
    const queryParams = new URLSearchParams(searchParams?.toString())
    queryParams.set("page", pageNumber.toString())
    router.replace(`/${sellerSlug}?${queryParams.toString()}`)
  }

  const totalPage = listProductSearch?.total
    ? Math.ceil(listProductSearch?.total / perPage)
    : 1

  return (
    <section id="seller-catalogue-wrapper" className="w-full">
      {(!!listProductSearch?.items || !!keyword) && (
        <SearchAndSortFields
          keywordSearch={keyword}
          sellerSlug={sellerSlug}
          handleOnChangeKeyWord={handleOnChangeKeyWord}
          handleKeywordBlur={handleKeywordBlur}
          handleSortChange={handleSortChange}
          sortBy={sortBy}
        />
      )}
      {listProductSearch?.items === null ? (
        <div className="border-1 mt-8 flex items-center justify-center rounded-4 border border-tertiary50 p-4">
          <EmptyState
            className="mt-6"
            imageUrl={`${CDN_URL}/images/empty-product.svg`}
            title="Maaf, produk tidak ditemukan"
          />
        </div>
      ) : (
        <>
          <div className="mt-6 grid grid-cols-1 gap-x-2 gap-y-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5">
            {isLoading
              ? Array.from({ length: 5 }).map((item, idx) => (
                  <Skeleton
                    key={idx}
                    width="w-full"
                    height="h-[344px]"
                    variant="rounded"
                    bgColor="bg-tertiary50"
                  />
                ))
              : listProductSearch?.items?.map((product, idx) => {
                  if (idx > 14) {
                    return (
                      <LazyLoadComponent key={`product-card-${idx}`}>
                        <Link
                          key={`product-card-${idx}`}
                          href={ROUTE.product(product.username, product.slug)}
                        >
                          <ProductCard
                            product={product}
                            isNewTaxCalculationEnabled={
                              isNewTaxCalculationEnabled
                            }
                            isRatingReviewEnabled={isRatingReviewEnabled}
                            isProductAccelerationEnabled={
                              isProductAccelerationEnabled
                            }
                          />
                        </Link>
                      </LazyLoadComponent>
                    )
                  }
                  return (
                    <Link
                      key={`product-card-${idx}`}
                      href={ROUTE.product(product.username, product.slug)}
                    >
                      <ProductCard
                        product={product}
                        isNewTaxCalculationEnabled={isNewTaxCalculationEnabled}
                        isRatingReviewEnabled={isRatingReviewEnabled}
                        isProductAccelerationEnabled={
                          isProductAccelerationEnabled
                        }
                      />
                    </Link>
                  )
                })}
          </div>
          <div className="mt-6 flex w-full justify-between">
            <Pagination
              currentPage={parseInt(pageParam)}
              numberOfPages={totalPage}
              onPageChange={handlePageChange}
            />
            <div className="flex items-center gap-4">
              <Select
                styles={{
                  menu: (baseStyles) => ({
                    ...baseStyles,
                    zIndex: 2,
                  }),
                  container: (baseStyles) => ({
                    ...baseStyles,
                    background: "#F5F6F9",
                    borderRadius: "4px",
                  }),
                  control: (baseStyles) => ({
                    ...baseStyles,
                    background: "#F5F6F9",
                    border: "none !important",
                  }),
                }}
                isSearchable={false}
                value={selectedPerpage}
                options={sellerCataloguePerpageOptions}
                onChange={(value) => {
                  setSelectedPerpage(value as TSelectOption)
                }}
              />
              <div className="text-tertiary300">
                Total {listProductSearch?.total}
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  )
}

export default SellerCatalogue
